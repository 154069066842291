import { ServiceType } from '@/models/enums';

interface UseMobileAppsOverviewReturn {
  isFilterVisible: Ref<boolean>;
  selectedServiceType: Ref<ServiceType>;
  currentCarouselItems: ComputedRef<MobileAppCarouselItem[]>;
  filterOptions: ComputedRef<{ value: ServiceType; label: string }[]>;
}

export function useMobileAppsOverview(): UseMobileAppsOverviewReturn {
  const { clientState } = storeToRefs(useClientStore());
  const { track } = useUserTracking();

  const { hasStaticLinks: hasSharingStaticLinks } = useAppStoreLinks(
    ServiceType.Sharing
  );
  const { hasStaticLinks: hasLeasingStaticLinks } = useAppStoreLinks(
    ServiceType.Leasing
  );

  const { t } = useClientI18n();

  const filterOptions = computed<{ value: ServiceType; label: string }[]>(
    () => [
      { value: ServiceType.Sharing, label: t('common__sharing') },
      { value: ServiceType.Leasing, label: t('common__leasing') },
    ]
  );

  const sharingCarouselItems = computed(
    () => clientState.value.mobileAppsOverview?.sharing?.carousel_items ?? []
  );
  const leasingCarouselItems = computed(
    () => clientState.value.mobileAppsOverview?.leasing?.carousel_items ?? []
  );

  const isFilterVisible = computed(() => {
    const isSharingVisible =
      clientState.value.services.sharing &&
      hasSharingStaticLinks.value &&
      sharingCarouselItems.value.length > 0;

    const isLeasibleVisible =
      clientState.value.services.leasing &&
      hasLeasingStaticLinks.value &&
      leasingCarouselItems.value.length > 0;

    return isSharingVisible && isLeasibleVisible;
  });

  const currentCarouselItems = computed(() =>
    selectedServiceType.value === ServiceType.Sharing
      ? sharingCarouselItems.value
      : leasingCarouselItems.value
  );

  const selectedServiceType = ref(
    hasSharingStaticLinks.value &&
      clientState.value.preferredServiceType === ServiceType.Sharing
      ? ServiceType.Sharing
      : ServiceType.Leasing
  );

  watch(selectedServiceType, value => {
    track.toggleServiceTypeOnMobileAppsCarousel({
      serviceType: value,
    });
  });

  return {
    isFilterVisible,
    selectedServiceType,
    currentCarouselItems,
    filterOptions,
  };
}
