<template lang="pug">
.FcwAppStoresFallback
  .FcwAppStoresFallback__content(v-show="!isLandscapeOk || hideTabletHint")
    .FcwAppStoresFallback__header
      FcwRouterLink(
        :location="homepage"
        hide-arrow
      )
        FcwClientLogo
      FSelect(
        v-if="isFilterVisible"
        v-model="selectedServiceType"
        :options="filterOptions"
        hide-hint
        size="small"
      )

    FcwMobileAppsCarousel(
      v-model="selectedServiceType"
      :carousel-items="currentCarouselItems"
    )
  .FcwAppStoresFallback__bottomContent
    .FcwAppStoresFallback__bottomContent__links
      FcwRouterLink(
        :location="{ name: 'terms-and-conditions' }"
        target="_blank"
        hide-arrow
        color="neutral--light-5"
      )
        span {{ t('common__terms_and_conditions') }}
      FcwRouterLink(
        :location="{ name: 'legal-notice' }"
        target="_blank"
        hide-arrow
        color="neutral--light-5"
      )
        span {{ t('common__legal_notice') }}
    .FcwAppStoresFallback__bottomContent__locale
      FcwLocaleSelect(
        display-mode="code"
        size="small"
      )

  .FcwAppStoresFallback__tabletPortrait(
    v-show="isLandscapeOk && !hideTabletHint && isMounted"
  )
    h4 {{ t('common__rotate_screen_to_use_the_app') }}
    FIcon(
      name="rotateBack"
      size="80"
    )
</template>

<style lang="stylus">
.FcwAppStoresFallback
  background-color var(--color--neutral--dark-4)

.FcwAppStoresFallback__header
  border-radius 0 0 rem(40) rem(40)
  background-color var(--color--neutral--light-5)
  padding rem(16) rem(24)
  display flex
  align-items center
  justify-content space-between
  gap rem(16)
  position sticky
  top 0
  z-index 10

  +media-up('sm')
    display none

.FcwAppStoresFallback__content
  margin-bottom rem(56)

.FcwAppStoresFallback__bottomContent
  margin-top rem(24)
  display flex
  justify-content space-between
  align-items center
  position fixed
  bottom rem(24)
  left rem(24)
  right rem(16)
  gap rem(8)

.FcwAppStoresFallback__bottomContent__links
  display flex
  flex-wrap wrap
  white-space nowrap
  gap rem(16)

  +media-down('sm')
    color var(--color--neutral--light-5)
</style>

<script setup lang="ts">
import { useMobileAppsOverview } from '@/composables/useMobileAppsOverview';

export interface FcwAppStoresFallbackProps {
  /**
   * Hide the hint which invites the user to rotate its device
   * when device height is tall enough to use the app in landscape mode
   */
  hideTabletHint?: boolean;
}

const clientStore = useClientStore();
const { clientState } = storeToRefs(clientStore);

const { t } = useClientI18n();

const { breakpointSm } = clientState.value.theme;
const { height } = useWindowSize();
const { homepage } = useClientStore();

const isMounted = useMounted();

const isLandscapeOk = computed(() => {
  return Number(breakpointSm.replace('px', '')) < height.value;
});

const {
  isFilterVisible,
  currentCarouselItems,
  selectedServiceType,
  filterOptions,
} = useMobileAppsOverview();

withDefaults(defineProps<FcwAppStoresFallbackProps>(), {
  hideTabletHint: false,
});
</script>
